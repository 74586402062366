import { useStaticQuery, graphql } from "gatsby"

export const useAppCTAPhotos = () => {
  const {
    tenAdventures: { appCtaBgImage: bgImage, appCtaPhoneImage: phoneImage },
  } = useStaticQuery(graphql`
    query AppCTAPhotos {
      tenAdventures {
        appCtaBgImage: responsiveImage(id: "app-cta-background", idType: SLUG) {
          image {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        appCtaPhoneImage: responsiveImage(id: "app-cta-phones", idType: SLUG) {
          image {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
      }
    }
  `)
  return { bgImage, phoneImage }
}
