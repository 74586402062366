import React from "react"
import PrimaryLayout from "@src/layouts/primary"
import styled, { css } from "styled-components"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import PageHelmet from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveSection from "@src/components/container-responsive-section"
import Link from "@src/components/core-link"
import { useAppCTAPhotos } from "@src/hooks/useAppCTAPhotos"
import SocialProofTestimonial from "@src/components/content-social-proof-testimonial"
import CarouselContainer from "@src/components/container-carousel"
import { SITE_DOMAIN } from "@src/utils/constants"

const screenPadding = css`
  @media only screen and (${props => props.theme.screen.medium.min}) {
    padding: 0 100px;
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    padding: 0 200px;
  }
`
const YellowCircle = styled.div`
  position: absolute;
  top: -40px;
  left: -30px;
  width: 330px;
  height: 356px;
  z-index: 0;
  border-radius: 100%;
  background-image: linear-gradient(
    151deg,
    rgba(254, 197, 27, 0.2) 46%,
    rgba(254, 197, 27, 0) 87%
  );

  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: 660px;
    height: 450px;
  }
`
const PhoneImageWrapper = styled(ImageWrapper)`
  height: 300px;
`
const HeaderAndFooterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  & > .link-div {
    display: flex;
    flex-direction: column;
    & > .app-links {
      align-self: center;
    }
    width: 100%;
    & > h1 {
      font-size: 30px;
      font-weight: 900;
      line-height: normal;
      margin-top: 30px;
    }
  }
  & .lazyload-wrapper > img {
    object-fit: contain;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    display: grid;
    grid-template-columns: 1fr 305px;
    gap: 20px;

    & > .link-div {
      grid-column: 1 / 2;
      & > .app-links {
        align-self: flex-start;
        margin-left: -10px;
      }
    }
    & > div:last-child {
      grid-column: 2 / 3;
    }
  }
  ${screenPadding}
`
const Subheader = styled.h2`
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
`
const CollageWrapper = styled(ImageWrapper)`
  width: 300px;
  height: 375px;
`
const ImageCollage = styled.div`
  margin-top: 14px;
`
const CollageDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  & > ${ImageCollage} {
    align-self: center;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    display: grid;
    grid-template-columns: 305px 1fr;
    grid-template-rows: auto;
    gap: 20px;

    & > ${ImageCollage} {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    & > .text-content {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
  }
  ${screenPadding}
`
const YellowListDiv = styled.div`
  ${screenPadding}
`
const YellowOrderedList = styled.ol`
  list-style: none;
  counter-reset: item;
  margin-bottom: 15px;
  & > li {
    display: flex;
    counter-increment: item;
    margin-bottom: 5px;
    & > div {
      max-width: 80%;
      & > h3 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 4px;
      }
    }
    &:before {
      margin-top: 10px;
      margin-right: 10px;
      content: counter(item);
      background: ${props => props.theme.primary};
      border-radius: 100%;
      color: white;
      height: 1.5em;
      width: 1.6em;
      padding-top: 4px;
      text-align: center;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`
const StyledCarousel = styled(CarouselContainer)`
  margin: auto 0;
  width: 100%;
  align-items: center;
  & > button.prev {
    left: 2;
  }
  & > button.next {
    right: 2;
  }
  & .testimonial {
    flex: 0 0 auto;
    height: 220px;
    min-width: 265px;
    width: 100%;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & .testimonial {
      width: 50%;
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    & .testimonial {
      width: 33%;
      padding: 35px 50px 30px;
    }
  }
`
const SocialProofContainer = styled(ResponsiveSection)`
  padding: 0 20px;
  height: 300px;
  margin-bottom: 30px;
  background-color: ${props => props.theme.primary};
`
const StoreBadgeWrappers = styled(ImageWrapper)`
  width: 165px;
  // Required or else app store icon will be larger
  & > .lazyload-wrapper > img.app-store-download {
    width: 90%;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: 210px;
  }
`
const AppLinks = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 40px;
  z-index: 1;
  & > :first-child {
    padding: 0 10px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & > :first-child {
      padding-right: 20px;
    }
  }
`
const AppStoreLinks = () => {
  const appStoreLink = process.env.GATSBY_10A_APP_STORE_LINK
  const playStoreLink = process.env.GATSBY_10A_PLAY_STORE_LINK
  return (
    <AppLinks className="app-links">
      <Link to={playStoreLink}>
        <Image
          src="/icons/google-play-badge-cropped.png"
          alt="Play store icon"
          wrapper={StoreBadgeWrappers}
        />
      </Link>
      <Link to={appStoreLink}>
        <Image
          className="app-store-download"
          src="/icons/app-store-badge.svg"
          alt="App store icon"
          wrapper={StoreBadgeWrappers}
        />
      </Link>
    </AppLinks>
  )
}
const appDetails = [
  {
    header: "Find the trail that’s right for you",
    subtext:
      "Search by region, filter by outdoor activity, or choose your adventure based on elevation gain, distance, or duration.",
  },
  {
    header: "GPS Trail tracking and maps",
    subtext:
      "Download your map and then see where you are while you are on the trail.",
  },
  {
    header: "Track your stats",
    subtext:
      "Get real-time trail stats while you are on the trail so you can track your progress.",
  },
  {
    header: "Join the 10Adventures community",
    subtext:
      "Share your adventures with a community of outdoor enthusiasts and browse photos and reviews from others on the trails.",
  },
]

const appTestimonials = [
  {
    content:
      "<p>Highly recommend this app for those looking to stay on track on the trail, track their progress and get amazing value with an app purchase.</p>",
    author: "frankieandlilah",
  },
  {
    content:
      "<p>Great trail previews, and precise directions to the trailheads. Perfect for planning adventures.</p>",
    author: "SDW403",
  },
  {
    content:
      "<p>The trail descriptions on the app are great, and I find it doesn’t use much battery while I’m outdoors. It’s definitely a good thing to keep handy!</p>",
    author: "CDE146",
  },
  {
    content:
      "<p>I love that as soon as I open the app it has great suggestions all around me, I’m finding new spots I didn’t even know existed. Great app!!</p>",
    author: "TousledTraveller",
  },
  {
    content: "<p>Loving this app. Easy to use and lots of great trails.</p>",
    author: "dc_cab",
  },
]

const AppPage = () => {
  const { phoneImage } = useAppCTAPhotos()
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle("Get the app!")}
        meta={{
          canonical: `${SITE_DOMAIN}/trails-app/`,
          robots: ["follow", "index"],
        }}
      />
      <ResponsiveSection>
        <HeaderAndFooterDiv>
          <div className="link-div">
            <YellowCircle />
            <h1>A world of adventure at your fingertips</h1>
            <p>
              Access everything you need to not get lost on the trails with
              downloadable maps, guidebook quality info, and the ability to
              track your stats -- all in one app.
            </p>
            <AppStoreLinks />
          </div>
          <Image
            wrapper={PhoneImageWrapper}
            src="/images/appSingleMobile.png"
            alt="Single Phone Image"
          />
        </HeaderAndFooterDiv>
      </ResponsiveSection>
      <ResponsiveSection align="left">
        <CollageDiv>
          <div className="text-content">
            <Subheader>Adventure more. Spend less.</Subheader>
            <p>
              Downloadable maps, route guides, elevation graphs and more.
              Finally an app that keeps you safe and on track for less than the
              price of a sandwich.
            </p>
          </div>
          <ImageCollage>
            <Image
              wrapper={CollageWrapper}
              src="/images/appCollage.png"
              alt="Photo Collage"
            />
          </ImageCollage>
        </CollageDiv>
      </ResponsiveSection>

      <ResponsiveSection align="left">
        <YellowListDiv>
          <Subheader>Making it easy to find your next adventure.</Subheader>
          <p>
            Choose from thousands of trails and set your adventure plan in
            motion.
          </p>
          <YellowOrderedList>
            {appDetails.map((detail, index) => (
              <li key={index}>
                <div>
                  <h3>{detail.header}</h3>
                  <p>{detail.subtext}</p>
                </div>
              </li>
            ))}
          </YellowOrderedList>
        </YellowListDiv>
      </ResponsiveSection>
      <SocialProofContainer>
        <StyledCarousel>
          {appTestimonials.map((testimonial, index) => (
            <SocialProofTestimonial
              key={index}
              content={testimonial.content}
              author={{ name: testimonial.author }}
            />
          ))}
        </StyledCarousel>
      </SocialProofContainer>
      <ResponsiveSection>
        <HeaderAndFooterDiv>
          <div className="link-div">
            <Subheader>All of your adventures all in one place.</Subheader>
            <p>
              Hiking, cycling, snowshoeing and more. Discover a world of
              adventure at your fingertips.
            </p>
            <AppStoreLinks />
          </div>
          <Image wrapper={PhoneImageWrapper} {...phoneImage.image} />
        </HeaderAndFooterDiv>
      </ResponsiveSection>
    </PrimaryLayout>
  )
}

export default AppPage
